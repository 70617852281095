document.addEventListener("DOMContentLoaded", function () {
  var forms = document.querySelectorAll("form");
  const disallowedDomains = [
    "gmail.com",
    "yahoo.com",
    "ymail.com",
    "hotmail.com",
    "aol.com",
    "me.com",
    "icloud.com",
    "outlook.com",
  ];

  forms.forEach(function (form) {
    var submitButton = form.querySelector('input[type="submit"]');
    const emailInput = form.querySelector("#id_email");

    if (submitButton) {
      form.addEventListener("submit", function () {
        submitButton.disabled = true;
      });
    }

    if (emailInput) {
      emailInput.addEventListener("blur", function () {
        const emailDomain = this.value.split("@")[1];
        const helpText = this.parentNode.querySelector(".validation-message");

        if (disallowedDomains.includes(emailDomain)) {
          if (helpText) {
            helpText.textContent =
              "We apologize, this email domain is not allowed. Please use a company email address.";
          }
          if (submitButton) {
            submitButton.disabled = true;
          }
        } else {
          if (helpText) {
            helpText.textContent = "";
          }
          if (submitButton) {
            submitButton.disabled = false;
          }
        }
      });
    }
  });
});
