document.querySelectorAll(".collapse-button").forEach((button) => {
  button.addEventListener("click", () => {
    // Toggle aria-expanded
    const expanded = button.getAttribute("aria-expanded") === "true";
    button.setAttribute("aria-expanded", !expanded);

    // Toggle hidden class and aria-hidden on SVGs
    const svgs = button.querySelectorAll("svg");
    svgs.forEach((svg) => {
      const isHidden = svg.classList.toggle("hidden");
      svg.setAttribute("aria-hidden", isHidden);
    });

    // Toggle hidden class on dd element
    const ddId = button.getAttribute("aria-controls");
    const ddElement = document.getElementById(ddId);
    ddElement.classList.toggle("hidden");
  });
});
