window.document.addEventListener("DOMContentLoaded", function () {
  // Get the button that opens the menu
  var menuButton = document.querySelector("#navMobileMenuButton");
  // Get the button that closes the menu
  var closeButton = document.querySelector("#navMobileMenuCloseButton");

  // Get the mobile menu and the backdrop
  var mobileMenu = document.querySelector("#navMobileMenu");
  var backdrops = document.querySelectorAll(".backdrop");

  // Add an event listener to the button
  menuButton.addEventListener("click", function () {
    // Toggle the 'hidden' class on the mobile menu
    mobileMenu.classList.toggle("hidden");

    // Toggle the 'hidden' class on each backdrop
    backdrops.forEach(function (backdrop) {
      backdrop.classList.toggle("hidden");
    });
  });

  // Add an event listener to the close button
  closeButton.addEventListener("click", function () {
    // Add the 'hidden' class to the mobile menu
    mobileMenu.classList.add("hidden");

    // Add the 'hidden' class to each backdrop
    backdrops.forEach(function (backdrop) {
      backdrop.classList.add("hidden");
    });
  });

  const themeButton = document.getElementById("theme-button");
  const themeMenu = document.getElementById("theme-menu");

  // Function to toggle the dropdown
  function toggleThemeDropdown() {
    let isDropdownOpen = !themeMenu.classList.contains("hidden");
    if (isDropdownOpen) {
      themeMenu.classList.add("hidden");
    } else {
      themeMenu.classList.remove("hidden");
    }
  }

  // Toggle the dropdown when the button is clicked
  themeButton.addEventListener("click", toggleThemeDropdown);

  // Close the dropdown when clicking outside of it
  window.addEventListener("click", (event) => {
    if (
      !themeButton.contains(event.target) &&
      !themeMenu.contains(event.target)
    ) {
      themeMenu.classList.add("hidden");
    }
  });
});
